import React, { useState, useEffect, useRef } from 'react';
import SmartAgent from '@missionlabs/smartagent-app-components/dist/smartagent';
import { Box, Button } from '@missionlabs/smartagent-app-components';
import '../../node_modules/@missionlabs/smartagent-app-components/dist/index.css';
import Tickets from './Tickets/Tickets';
import { ITicket, TicketContact } from '../../../types';
import { getTickets } from '../hooks/getTickets';
import { BeatLoader } from 'react-spinners';
import StatusFilter, { TicketStatus } from './StatusFilter';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Main: React.FC = (props: any) => {
    const sm = useRef<SmartAgent>(props.sm || new SmartAgent());
    const [contactId, setContactId] = useState('');
    const [token, setToken] = useState('');
    const [tickets, setTickets] = useState<ITicket[]>([]);
    const [statusFilter, setStatusFilter] = useState(TicketStatus.Open);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        setup();
        // eslint-disable-next-line
    }, []);

    const setup = async () => {
        let contact;
        if (props.contact) {
            contact = props.contact;
            sm.current.init();
        } else {
            contact = await sm.current.init();
        }

        console.log('[tw-tickets-plugin] got contact', contact);
        setToken(await sm.current.getToken());
        setContactId(contact.ID);

        sm.current.on('contact_updated', async (contact: TicketContact) => {
            if (contact.ID) {
                setToken(await sm.current.getToken());
                setContactId(contact.ID);
            }
        });

        sm.current.showPlugin();
    };

    const handleGetTickets = () => {
        setError(false);
        setLoading(true);
        getTickets(contactId, token)
            .then((data) => setTickets(data))
            .catch((e) => {
                console.log(e);
                setError(true);
            })
            .finally(() => setLoading(false));
    };

    const renderInner = () => {
        const filteredTickets =
            statusFilter === TicketStatus.Open
                ? tickets.filter((ticket) => ticket.status === TicketStatus.Open)
                : statusFilter === TicketStatus.Closed
                ? tickets.filter((ticket) => ticket.status === TicketStatus.Closed)
                : tickets;

        return (
            <>
                {tickets.length > 0 ? (
                    <div className="plugin">
                        <StatusFilter
                            statusFilter={statusFilter}
                            setStatusFilter={setStatusFilter}
                        />
                        <Tickets tickets={filteredTickets} />
                    </div>
                ) : loading ? (
                    <div className="plugin-loading" data-testid="plugin-loading">
                        <BeatLoader />
                    </div>
                ) : (
                    <div className="plugin-get-tickets flex middle center column">
                        {error ? <h3>Error retrieving tickets</h3> : <h3>Not loaded</h3>}
                        <Button
                            className="plugin-get-tickets__button"
                            onClick={() => handleGetTickets()}
                        >
                            Retrieve tickets
                        </Button>
                    </div>
                )}
            </>
        );
    };

    const isEmbedded = window.parent !== window;
    return isEmbedded ? (
        renderInner()
    ) : (
        <Box alt header="Tickets" collapse>
            {renderInner()}
        </Box>
    );
};

export default Main;
