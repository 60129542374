import React from 'react';
import downChevronIcon from '../../icons/down-chevron-green.svg';

const HeaderItem = ({
    label,
    className,
    onClick
}: {
    label: string;
    className: string;
    onClick?: () => void;
}) => (
    <h2 className={className} onClick={onClick}>
        {label}
        <img src={downChevronIcon} alt={label} />
    </h2>
);

export default HeaderItem;
