import React from 'react';
import { Button, TriggeredPopup } from '@missionlabs/smartagent-app-components';
import filterIcon from '../icons/icon-filter.svg';
import tickIcon from '../icons/tick-fill.svg';

export enum TicketStatus {
    Open = 'Open',
    Closed = 'Closed',
    All = 'All'
}

interface Props {
    statusFilter: TicketStatus;
    setStatusFilter: (ticketStatus: TicketStatus) => void;
}

const StatusFilter: React.FC<Props> = ({ statusFilter, setStatusFilter }) => {
    const handleKeyDown = (e: React.KeyboardEvent, status: TicketStatus) => {
        if (e.keyCode === 13) {
            handleStatusFilterChange(status);
        }
    };

    const handleStatusFilterChange = (status: TicketStatus) => {
        if (status === statusFilter) return;
        setStatusFilter(status);
    };

    return (
        <div className="ticket-status flex end">
            <TriggeredPopup
                fromRight
                closeOnClickOutside
                trigger={
                    <Button className="flex middle" data-testid="status-filter">
                        <span>Status</span>
                        <img src={filterIcon} title="Filter" alt="Filter" />
                    </Button>
                }
            >
                <ul className="ticket-status-filter-list">
                    <li className="flex between middle">
                        <Button
                            className="ticket-status-filter-list__option"
                            onClick={() => handleStatusFilterChange(TicketStatus.All)}
                            onKeyDown={(e) => handleKeyDown(e, TicketStatus.All)}
                        >
                            <span>{TicketStatus.All}</span>
                            {statusFilter === TicketStatus.All ? (
                                <img src={tickIcon} alt="Selected" title="Selected" />
                            ) : null}
                        </Button>
                    </li>
                    <li className="flex between middle">
                        <Button
                            className="ticket-status-filter-list__option"
                            onClick={() => handleStatusFilterChange(TicketStatus.Open)}
                            onKeyDown={(e) => handleKeyDown(e, TicketStatus.Open)}
                        >
                            <span>{TicketStatus.Open}</span>
                            {statusFilter === TicketStatus.Open ? (
                                <img src={tickIcon} alt="Selected" title="Selected" />
                            ) : null}
                        </Button>
                    </li>
                    <li className="flex between middle">
                        <Button
                            className="ticket-status-filter-list__option"
                            onClick={() => handleStatusFilterChange(TicketStatus.Closed)}
                            onKeyDown={(e) => handleKeyDown(e, TicketStatus.Closed)}
                        >
                            <span>{TicketStatus.Closed}</span>
                            {statusFilter === TicketStatus.Closed ? (
                                <img src={tickIcon} alt="Selected" title="Selected" />
                            ) : null}
                        </Button>
                    </li>
                </ul>
            </TriggeredPopup>
        </div>
    );
};

export default StatusFilter;
