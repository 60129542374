import axios from 'axios';
import { ITicket } from '../../../types';
import { BASE_URL } from '../utils';

export const getTickets = async (customerId: string, token: string): Promise<ITicket[]> => {
    const url = `${BASE_URL}/tickets/${customerId}`;
    return (
        await axios({
            url,
            headers: {
                'X-Amz-Security-Token': token
            }
        })
    ).data;
};
